
import { CreateMsg } from '../../repositories/chat'
 import {msgRequest} from '../../models/chat'
 import {bus} from '../../main'
import {mapGetters} from 'vuex'

  export default {
    name:"msgModal",
    data(){
      return {
        msg : ""
      }
    },
    methods:{
      submit(){
        let ToId = this.id
        let Msg = this.msg
        let request :msgRequest = {
          ToId ,
          Msg 
        }
        CreateMsg(request).then((res:any) => {
          this.$store.commit("ui/respondModal" , {status : false , id : 0})
          bus.$emit('getTableData')
        })
      }
    },
    computed:{
        ...mapGetters({
            active : 'ui/respondModalStatus',
            id : 'ui/respondModalUserId'
        })
    }
  }
