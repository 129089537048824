
import {mapGetters} from 'vuex'

  export default {
    data(){
      return {
        msg : ""
      }
    },
    methods:{
      submit(){
          this.$store.commit("ui/pendModal" ,false)
      }
    },
    computed:{
        ...mapGetters({
            active : 'ui/pendModalStatus',
        })
    }
  }
